<template>
    <div class="page">
        <div class="title">消息记录</div>
        <div class="main">
            <div class="box">
                <div class="ops">
                    <el-switch :disabled="disabled" @change="handleChange" v-model="pageinfo.pageFlag"
                        inactive-text="加载全部">
                    </el-switch>
                    <div class="pageinfo" v-if="!pageinfo.pageFlag">
                        <el-pagination layout="total,prev, pager, next" @current-change="pagechange"
                            :current-page="pageinfo.pagenum" :page-size="pageinfo.pagesize"
                            :total="pageinfo.total"></el-pagination>
                    </div>
                </div>
                <div class="datainfo">
                    <el-table :data="userList" :header-cell-style="{ background: '#e8e8e8' }"
                        :row-style="{ height: '55px' }" border row-key="id" style="width: 100%">
                        <el-table-column prop="nick" label="用户名" align="left"></el-table-column>
                        <el-table-column prop="registerTime" label="注册时间" align="center"></el-table-column>
                        <el-table-column prop="vipTime" label="vip到期时间" align="center"></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            userList: [],
            pageinfo: {
                pagenum: 1,
                pagesize: 10,
                total: 0,
                pageFlag: false
            },
            disabled: false
        };
    },
    created() {
        this.getUserList();
    },
    methods: {
        getUserList() {
            let data = {
                "pageNum": this.pageinfo.pagenum,
                "pageSize": this.pageinfo.pagesize,
                "pageFlag": !this.pageinfo.pageFlag
            }
            this.disabled = true;
            this.axios
                .post("users/getUserSubordinateList", this.Qs.stringify(data), { headers: { 'Content-type': 'application/x-www-form-urlencoded' } })
                .then((res) => {
                    console.log("fasfsf",res);
                    if (res.code === this.global.CODE.SUCCESS) {
                        let dataList = [];
                        if (this.pageinfo.pageFlag) {
                            dataList = res.data;
                        } else {
                            dataList = res.data.list;
                            this.pageinfo.total = res.data.total;
                        }
                        // if (dataList.length == this.pageinfo.total) {
                        //     this.pageinfo.pageFlag = true;
                        // }
                        this.userList = dataList.map((v) => {
                            return {
                                registerTime: this.parseTime(new Date(v.registerTime).getTime()),
                                vipTime: this.parseTime(new Date(v.vipTime).getTime()),
                                nick: v.nick
                            }
                        });
                    }
                    this.disabled = false;
                }).catch(error => {
                    this.$message.error(error);
                    this.disabled = false;
                });
        },
        handleChange() {
            console.log(this.pageinfo.pageFlag);
            this.pageinfo.pagenum = 1;
            this.pageinfo.pagesize = 10;
            this.getUserList();
        },
        pagechange(v) {
            console.log(v);
            this.pageinfo.pagenum = v;
            this.getUserList();
        },
        // 日期格式化
        parseTime(time, pattern) {
            if (arguments.length === 0 || !time) {
                return null
            }
            const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
            let date
            if (typeof time === 'object') {
                date = time
            } else {
                if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
                    time = parseInt(time)
                } else if (typeof time === 'string') {
                    time = time.replace(new RegExp(/-/gm), '/');
                }
                if ((typeof time === 'number') && (time.toString().length === 10)) {
                    time = time * 1000
                }
                date = new Date(time)
            }
            const formatObj = {
                y: date.getFullYear(),
                m: date.getMonth() + 1,
                d: date.getDate(),
                h: date.getHours(),
                i: date.getMinutes(),
                s: date.getSeconds(),
                a: date.getDay()
            }
            const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
                let value = formatObj[key]
                // Note: getDay() returns 0 on Sunday
                if (key === 'a') {
                    return ['日', '一', '二', '三', '四', '五', '六'][value]
                }
                if (result.length > 0 && value < 10) {
                    value = '0' + value
                }
                return value || 0
            })
            return time_str
        }
    },
};
</script>

<style scoped lang="scss">
.title {
    font-size: 18px;
    font-weight: bold;
    color: #313131;
}

.main {
    min-width: 780px;
    background-color: #fff;
    border-radius: 12px;
    margin: 15px 0;
    padding: 28px;
}

.main .box {
    border-radius: 12px;
    box-shadow: 0 2px 10px 0 #ddd;
    // margin-top: 30px;
    padding: 20px;
    color: #666;
}

.box .n1 {
    display: flex;
    align-items: center;
}

.box .n1 .hlhs {
    width: 2px;
    height: 16px;
}

.box .n1 .txt {
    margin-left: 10px;
}

.box .n2 .item {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 20px;
}

.iimcount {
    font-size: 16px;
}

.box .n2 .item .action:hover {
    cursor: pointer;
}

.box .n2 .item .itv {
    margin-left: 20px;
}

.box .ops {
    display: flex;
    justify-content: space-between;
    min-height: 40px;
}

.box .ops .btngrp .btn.active {
    color: #ff6a00;
    border: 1px solid #ff6a00;
}

button:hover {
    cursor: pointer;
}

.box .ops .btngrp .btn {
    height: 28px;
    padding: 0 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
}

.box .ops .btngrp .btn_l {
    border-radius: 8px 0 0 8px;
    border-right: 0;
}

.box .ops .btngrp .btn_c {
    border-radius: 0;
}

.box .ops .btngrp .btn_r {
    border-radius: 0 8px 8px 0;
    border-left: 0;
}

.box .ops .pageinfo {}

.box .tabtitle {
    margin-top: 16px;
    background-color: #e8e8e8;
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    font-size: 14px;
}

.box .tabdata {
    min-height: 300px;
}
</style>
